<section class="coming-area">
    <div class="coming-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="coming-text">
                        <h1>Under Construction...</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea deserunt beatae voluptas, animi harum dolorum totam, praesentium, dolor sint aspernatur perspiciatis iusto labore nulla rerum earum! Sit commodi quia provident!</p>
                        
                        <div class="row coming-wrap">
                            <div class="col-6 col-sm-6 col-lg-3">
                                <div class="coming-inner">
                                    <h3 id="days"></h3>
                                    <p>Days</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-3">
                                <div class="coming-inner">
                                    <h3 id="hours"></h3>
                                    <p>Hours</p>
                                </div>
                            </div>
                            
                            <div class="col-6 col-sm-6 col-lg-3">
                                <div class="coming-inner">
                                    <h3 id="minutes"></h3>
                                    <p>Minutes</p>
                                </div>
                            </div>

                            <div class="col-6 col-sm-6 col-lg-3">
                                <div class="coming-inner">
                                    <h3 id="seconds"></h3>
                                    <p>Seconds</p>
                                </div>
                            </div>
                        </div>

                        <ul>
                            <li><a href="#"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#"><i class="icofont-pinterest"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>